import { App } from 'vue'
import { format } from 'date-fns'

export const prettifyNumber = (p: string | number): string => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')

export default {
  install(app: App<Element>): any {
    app.config.globalProperties.$filters = {
      prettifyNumber(a: string | number) {
        return prettifyNumber(a)
      },

      formatPrice(a: string) {
        const fixedNumber = (a).toString().replace(',', '.')
        const preparedValue = Number(fixedNumber)
        const numberWithPoint = parseFloat(fixedNumber).toFixed(2)
        const val = Number.isInteger(preparedValue) ? preparedValue : numberWithPoint
        const prettifiedNumber = prettifyNumber(val)
        const replacedNumber = (prettifiedNumber).toString().replace('.', ',')

        return replacedNumber
      },

      formatDate(a: string) {
        return format(new Date(a), 'dd.MM.yyyy')
      },

      formatDateTime(a: string) {
        return format(new Date(a), 'dd.MM.yyyy HH:mm:ss')
      },
    }
  }
}
