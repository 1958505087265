import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import './styles/index.sass'
import 'swiper/swiper-bundle.css'
import 'swiper/css/effect-fade'
import 'swiper/css/grid'
import 'swiper/css/thumbs'
import 'vue-advanced-cropper/dist/style.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'floating-vue/dist/style.css'
import 'viewerjs/dist/viewer.css'
import 'vue-slider-component/theme/antd.css'

const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

declare global {
  interface Window {
    isAuthenticated: boolean;
    indexPageUrl: string;
    privacyPolicyUrl: string;
    termsOfUseUrl: string;
    profileUrl: string;
    GOOGLE_MAP_API_KEY: string;
  }
}
