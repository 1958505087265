import { optionsT } from '@/router'
import useRoute from '@router/utils'
import { BASE_URL } from '@router/consts'

const List = () => import('../views/List.vue')
const Details = () => import('../views/Details.vue')
const Info = () => import('../views/Info.vue')

const p = (postfix: string) => `${BASE_URL}${postfix}`

export const LIST_URL = p('restaurant/:restaurantId/manager/list/')
export const INFO_URL = p('restaurant/:restaurantId/manager/:id/')
export const CREATE_URL = p('restaurant/:restaurantId/manager/create/')

export const LIST = 'restaurant:profile:manager:list'
export const DETAILS = 'restaurant:profile:manager:details'
export const CREATE = 'restaurant:profile:manager:create'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, { props: true }),
    useRoute(CREATE_URL, Details, CREATE, { props: true }),
    useRoute(INFO_URL, Info, DETAILS, { props: true }),
  ]
}
