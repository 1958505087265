<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_87_1788)">
      <path d="M2.75073 7.57617V6.95626C2.75073 3.66016 5.70399 0.988156 9.00009 0.988156C12.2962 0.988156 15.2494 3.66016 15.2494 6.95626V7.57617" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M4.99412 14.0273H2.75073V7.57735H4.99412V14.0273Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M2.75063 12.7305H1.7497C1.07406 12.7305 0.526367 12.1828 0.526367 11.5071V10.0971C0.526367 9.42147 1.07406 8.87377 1.7497 8.87377H2.75063" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M13.0059 14.0273H15.2492V7.57735H13.0059V14.0273Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M15.2493 12.7305H16.2502C16.9258 12.7305 17.4735 12.1828 17.4735 11.5071V10.0971C17.4735 9.42147 16.9258 8.87377 16.2502 8.87377H15.2493" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M15.2493 14.0257V16.25H10.1924" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
      <path d="M10.1924 16.251C10.1924 16.9095 9.65852 17.4434 8.99997 17.4434C8.34146 17.4434 7.80762 16.9095 7.80762 16.251C7.80762 15.5924 8.34146 15.0586 8.99997 15.0586C9.65852 15.0586 10.1924 15.5924 10.1924 16.251Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
    </g>
    <defs>
      <clipPath id="clip0_87_1788">
        <rect width="18" height="18" fill="white" transform="translate(0 0.21875)"/>
      </clipPath>
    </defs>
  </svg>
</template>