import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Map',
  props: {
  lat: {
    type: [String],
  },
  lng: {
    type: [String],
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const mapRef = ref(null)
const needFitBounds = ref(true)

const MAP_CONFIG = reactive({
  center: { lat: props.lat, lng: props.lng },
  options: { clickableIcons: false },
  zoom: 6,
})

const markerClickHandler = (m: any) => {
  MAP_CONFIG.center = m.location
}

const setBounds = () => {
  if (!needFitBounds.value) return

  // eslint-disable-next-line
  const bounds = new google.maps.LatLngBounds()

  const position = {
    lat: parseFloat(props.lat),
    lng: parseFloat(props.lng),
  }
  bounds.extend(position)

  mapRef.value.fitBounds(bounds)

  needFitBounds.value = false
}

const __returned__ = { props, mapRef, needFitBounds, MAP_CONFIG, markerClickHandler, setBounds, ref, reactive }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})