import { optionsT } from '@/router'
import useRoute from '@router/utils'
import { BASE_URL } from '@router/consts'

const List = () => import('./views/List.vue')

const p = (postfix: string) => `${BASE_URL}${postfix}`

export const LIST_URL = p('favourite/restaurant/list/')

export const LIST = 'profile:favourite:restaurant:list'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, {}),
  ]
}
