import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiRipple',
  setup(__props, { expose: __expose }) {
  __expose();

const ripples = ref([])

const createRipple = (event: MouseEvent) => {
  const container = event.currentTarget as HTMLElement
  const rect = container.getBoundingClientRect()
  const size = Math.max(rect.width, rect.height)
  const x = event.clientX - rect.left - size / 2
  const y = event.clientY - rect.top - size / 2

  const newRipple = {
    id: Date.now(),
    style: {
      width: `${size}px`,
      height: `${size}px`,
      top: `${y}px`,
      left: `${x}px`,
    },
  }

  setTimeout(() => {
    ripples.value.push(newRipple)
  }, 100)

  setTimeout(() => {
    ripples.value = ripples.value.filter(ripple => ripple.id !== newRipple.id)
  }, 600)
}

const __returned__ = { ripples, createRipple, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})