import { curry, isEmpty } from 'ramda'
import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import { jsonTransformer } from '@resource/transformer'

const filtersGetterBase = makeTemplateGetter('{;filters*}')
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null

const partGetter = (name, prefix) => {
  const base = makeTemplateGetter(`${prefix}{/${name}}`)
  return parameters => {
    const result = base(parameters)
    return result === prefix ? null : result
  }
}

const pageGetter = partGetter('page', 'page')
const labelGetter = makeTemplateGetter('{label}')
const queryGetter = makeTemplateGetter('{query}')
const getParametersGetter = makeTemplateGetter('{?limit,offset}')

const orderMap = {
  label: labelGetter,
  query: queryGetter,
  filters: filtersGetter,
  pagination_offset: getParametersGetter,
  page: pageGetter,
}

const initOrder = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
    'page',
    'pagination_offset',
  ],
}

export const urlGetter = curry(([prefix, postfix], { path, query = [] }, p) => {
  const base = { prefix, postfix }
  const pathParts = path.reduce((acc, x, i) => {
    acc[i] = orderMap[x] ? orderMap[x](p) : base[x]
    return acc
  }, Array.from({ length: path.length }, () => null))
  const queryParams = query.reduce((acc, x) => {
    acc.push(orderMap[x] ? orderMap[x](p) : base[x])
    return acc
  }, [])
  const filteredPath = pathParts.filter(x => x).join('/')
  const replaced = filteredPath.replace(/\/\//img, '/')
  const searchPath = queryParams.join('')
  const fullReplaced = replaced !== prefix ? `${replaced}/` : replaced

  let prettyUrl = decodeURIComponent(fullReplaced)

  if (prettyUrl.includes('limit=') && prettyUrl.endsWith('/')) {
    prettyUrl = prettyUrl.replace(/\/$/, '')
  }

  if (!isEmpty(p.filters)) {
    prettyUrl = prettyUrl.replace('/ajax/', '/ajax/filters/')
  }

  if (prettyUrl.includes('filter-attributes') && prettyUrl.endsWith('/filters/')) {
    if (!p.filters || (p.filters && isEmpty(p.filters))) {
      prettyUrl = prettyUrl.replace('/filters/', '/')
    }
  }

  return `${prettyUrl}${searchPath}`
})

export const friendlyUrlGenerator = (url, resource, order = initOrder) => resource
  .url(urlGetter(url, order))
  .transform(jsonTransformer)
