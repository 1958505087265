import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "control-input" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "multiselect__single" }
const _hoisted_6 = { class: "i-count i-count--variant_1" }
const _hoisted_7 = { class: "i-count__content" }
const _hoisted_8 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control-descriptor", { error: $props.errors[$props.name] }])
  }, [
    ($props.inputLabel)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.labelComponent), _mergeProps({
          key: 0,
          "input-label": $props.inputLabel,
          "is-required": $setup.isRequired,
          value: $setup.model
        }, _ctx.$attrs), null, 16, ["input-label", "is-required", "value"]))
      : _createCommentVNode("", true),
    _createVNode(_component_VeeField, _mergeProps({
      name: $props.name,
      rules: $props.rules
    }, _ctx.$attrs, {
      modelValue: $setup.model,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.model) = $event))
    }), {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode($setup["VueMultiselect"], _mergeProps({ class: "control-input__element" }, { ...$setup.defaultOptions, ..._ctx.$attrs }, {
            class: { error: $props.errors[$props.name], 'is-empty': !$props.options.length },
            options: $props.options,
            "track-by": $props.trackBy,
            label: $props.optionLabel,
            modelValue: $setup.model,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
              $setup.updateValue
            ]
          }), _createSlots({
            noResult: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("No elements found")), 1)
            ]),
            noOptions: _withCtx(() => [
              ($props.ajaxResults)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("Start typing the title")), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t("The list is empty")), 1))
            ]),
            selection: _withCtx(({ values }) => [
              (Array.isArray(values) && values.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(values.map(el => el[$setup.props.optionLabel]).join(', ')), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(values.length), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            _renderList($setup.eSlots, (name, key) => {
              return {
                name: name,
                fn: _withCtx((binded) => [
                  _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
                ])
              }
            })
          ]), 1040, ["class", "options", "track-by", "label", "modelValue"])
        ])
      ]),
      _: 3
    }, 16, ["name", "rules", "modelValue"]),
    _createVNode(_component_VeeError, { name: $props.name }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("span", _hoisted_8, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}