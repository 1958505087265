<template>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.5 4.58398C31.9462 4.58317 36.2965 5.87575 40.0209 8.30418C43.7453 10.7326 46.6829 14.192 48.4755 18.2607C50.2682 22.3295 50.8386 26.8318 50.1172 31.2191C49.3957 35.6063 47.4137 39.6889 44.4125 42.9694" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.5 18.334V36.6673" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.6654 27.5H18.332" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.72786 20.3379C4.99762 22.5577 4.61121 24.8762 4.58203 27.2129" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.48633 36.666C7.7731 39.6265 9.67079 42.2816 12.0551 44.4577" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.625 11.9972C11.2645 11.301 11.9464 10.6451 12.6669 10.0332" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.8086 49.0876C25.5233 51.1235 31.8121 50.8103 37.2963 48.2168" stroke="#00A082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>