import useGlobal from '@/composables/useGlobal'

interface Network {
  network: string;
  title: string;
  icon: string;
  color: string;
}

interface Day {
  title: string;
  key: string;
}

interface Choices {
  networksForShare: Network[];
  days: Day[];
}

export default function (): Choices {
  const { $t } = useGlobal()

  const days = [
    { day: 1, title: $t('Monday'), shortTitle: $t('Mon'), key: 'monday' },
    { day: 2, title: $t('Tuesday'), shortTitle: $t('Tue'), key: 'tuesday' },
    { day: 3, title: $t('Wednesday'), shortTitle: $t('Wed'), key: 'wednesday' },
    { day: 4, title: $t('Thursday'), shortTitle: $t('Thu'), key: 'thursday' },
    { day: 5, title: $t('Friday'), shortTitle: $t('Fri'), key: 'friday' },
    { day: 6, title: $t('Saturday'), shortTitle: $t('Sat'), key: 'saturday' },
    { day: 7, title: $t('Sunday'), shortTitle: $t('Sun'), key: 'sunday' },
  ]

  const networksForShare = [
    {
      network: 'facebook',
      title: $t('Facebook'),
      icon: 'icon-facebook',
      color: '#3b5998',
    },
    {
      network: 'twitter',
      title: $t('Twitter'),
      icon: 'icon-twitter',
      color: '#1da1f2',
    },
    {
      network: 'viber',
      title: $t('Viber'),
      icon: 'icon-viber',
      color: '#7360f2',
    },
    {
      network: 'whatsapp',
      title: $t('Whatsapp'),
      icon: 'icon-whatsapp',
      color: '#25d366',
    },
    {
      network: 'telegram',
      title: $t('Telegram'),
      icon: 'icon-telegram',
      color: '#0088cc',
    },
    {
      network: 'linkedin',
      title: $t('Linkedin'),
      icon: 'icon-linkedin',
      color: '#0a66c2',
    },
  ]

  return {
    networksForShare,
    days,
  }
}