import { createApp, App } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
// import { useLikesStore } from '@/store/likes'
import { useUserStore } from '@store/user'
import createAppRouter from '@router/index'
import Components from '@components/index'
import Directives from '@/directives/index'
import Filters from '@utils/filters'
import Modules from '@modules'
import i18n from './i18n'
import Icons from './icons'

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({
    created() {
      // this.getLikesData()

      if (window.isAuthenticated) {
        const userStore = useUserStore()

        userStore.getUserData()
      }
    },

    // methods: {
    //   getLikesData() {
    //     const likesStore = useLikesStore()

    //     likesStore.getLikesData()
    //   },
    // },
  })

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()
  
  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app.use(pinia)
  app.use(i18n)
  app.use(router)

  app.use(Modules)
  app.use(Filters)

  Components.install(app)

  Directives.register(app)
  Icons.register(app)

  app.mount('#app')

  return app
}
