import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_2 = { class: "g-cell" }
const _hoisted_3 = { class: "preference" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ds-caption ds-caption--size_2xxs ds-caption--color_white ds-caption--weight_medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_v_tooltip, { triggers: ['hover', 'click'] }, {
          popper: _withCtx(() => [
            _createElementVNode("p", _hoisted_5, _toDisplayString(item.name), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                class: "preference__icon",
                src: item.icon
              }, null, 8, _hoisted_4)
            ])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 256))
  ]))
}