import useRoute from '@router/utils'
import { optionsT } from '@router/index'

const Display = () => import('./views/Display.vue')

export const ROUTE_NAME = 'profile:settings'

export function createRoutes(options: optionsT): any {
  return [
    useRoute('', Display, ROUTE_NAME, {}),
  ]
}
