<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1896_1235)">
      <path d="M17.4699 23.28C17.2799 23.28 17.0899 23.21 16.9399 23.06C16.1199 22.24 15.6699 21.15 15.6699 20C15.6699 17.61 17.6099 15.67 19.9999 15.67C21.1499 15.67 22.2399 16.12 23.0599 16.94C23.1999 17.08 23.2799 17.27 23.2799 17.47C23.2799 17.67 23.1999 17.86 23.0599 18L17.9999 23.06C17.8499 23.21 17.6599 23.28 17.4699 23.28ZM19.9999 17.17C18.4399 17.17 17.1699 18.44 17.1699 20C17.1699 20.5 17.2999 20.98 17.5399 21.4L21.3999 17.54C20.9799 17.3 20.4999 17.17 19.9999 17.17Z" fill="#8E8E8E" />
      <path d="M13.5998 26.51C13.4298 26.51 13.2498 26.45 13.1098 26.33C12.0398 25.42 11.0798 24.3 10.2598 23C9.19984 21.35 9.19984 18.66 10.2598 17C12.6998 13.18 16.2498 10.98 19.9998 10.98C22.1998 10.98 24.3698 11.74 26.2698 13.17C26.5998 13.42 26.6698 13.89 26.4198 14.22C26.1698 14.55 25.6998 14.62 25.3698 14.37C23.7298 13.13 21.8698 12.48 19.9998 12.48C16.7698 12.48 13.6798 14.42 11.5198 17.81C10.7698 18.98 10.7698 21.02 11.5198 22.19C12.2698 23.36 13.1298 24.37 14.0798 25.19C14.3898 25.46 14.4298 25.93 14.1598 26.25C14.0198 26.42 13.8098 26.51 13.5998 26.51Z" fill="#8E8E8E" />
      <path d="M20.0006 29.02C18.6706 29.02 17.3706 28.75 16.1206 28.22C15.7406 28.06 15.5606 27.62 15.7206 27.24C15.8806 26.86 16.3206 26.68 16.7006 26.84C17.7606 27.29 18.8706 27.52 19.9906 27.52C23.2206 27.52 26.3106 25.58 28.4706 22.19C29.2206 21.02 29.2206 18.98 28.4706 17.81C28.1606 17.32 27.8205 16.85 27.4606 16.41C27.2006 16.09 27.2506 15.62 27.5706 15.35C27.8906 15.09 28.3605 15.13 28.6306 15.46C29.0206 15.94 29.4006 16.46 29.7406 17C30.8006 18.65 30.8006 21.34 29.7406 23C27.3006 26.82 23.7506 29.02 20.0006 29.02Z" fill="#8E8E8E" />
      <path d="M20.6896 24.27C20.3396 24.27 20.0196 24.02 19.9496 23.66C19.8696 23.25 20.1396 22.86 20.5496 22.79C21.6496 22.59 22.5696 21.67 22.7696 20.57C22.8496 20.16 23.2396 19.9 23.6496 19.97C24.0596 20.05 24.3296 20.44 24.2496 20.85C23.9296 22.58 22.5496 23.95 20.8296 24.27C20.7796 24.26 20.7396 24.27 20.6896 24.27Z" fill="#8E8E8E" />
      <path d="M9.99945 30.75C9.80945 30.75 9.61945 30.68 9.46945 30.53C9.17945 30.24 9.17945 29.76 9.46945 29.47L16.9395 22C17.2295 21.71 17.7095 21.71 17.9995 22C18.2895 22.29 18.2895 22.77 17.9995 23.06L10.5295 30.53C10.3795 30.68 10.1895 30.75 9.99945 30.75Z" fill="#8E8E8E" />
      <path d="M22.5307 18.22C22.3407 18.22 22.1507 18.15 22.0007 18C21.7107 17.71 21.7107 17.23 22.0007 16.94L29.4707 9.47C29.7607 9.18 30.2407 9.18 30.5307 9.47C30.8207 9.76 30.8207 10.24 30.5307 10.53L23.0607 18C22.9107 18.15 22.7207 18.22 22.5307 18.22Z" fill="#8E8E8E" />
    </g>
    <defs>
      <clipPath id="clip0_1896_1235">
        <rect width="40" height="40" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>
