import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "control-input" }
const _hoisted_2 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["control-input__element", { 'error': $props.errors[$props.name] }]),
      ref: "autocompleteInput",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.internal) = $event)),
      name: $props.name,
      placeholder: "",
      type: "text",
      autocomplete: "off",
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"])),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args)))
    }, null, 42, _hoisted_2), [
      [_vModelText, $setup.internal]
    ])
  ]))
}