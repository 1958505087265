import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue'
import { getMimeType, formatBytes, megabyte } from '@utils/files'
import { useConfirmWithPromise } from '@composables/useModalOpener'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlUploader',
  props: {
  modelValue: {},
  name: {
    type: String,
    default: 'upload',
  },
  inputId: {
    type: String,
    default: 'upload',
  },
  maxSize: {
    type: Number,
    default: 5,
  },
  maxFiles: {
    type: Number,
    default: 5,
  },
  acceptedFiles: {
    default: () => ['jpg', 'jpeg', 'png'],
  },
  needCropper: {
    type: Boolean,
  },
  multiple: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  label: {
    type: String,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const emit = __emit

const acceptedFilesLocal = ref('')
const files = ref([])
const fileErorrs = ref({
  limit: false,
  size: false,
  type: false,
  width: false,
  height: false,
})
const defaultFilesUpdated = ref(false)
const file = ref({})
const fileField = ref(null)

const notDeletedFiles = computed(() => {
  return files.value.filter(el => !el.Delete)
})

watch(() => props.modelValue, () => {
  if (!defaultFilesUpdated.value) {
    setDefaultFiles()
  }
})

onMounted(() => {
  acceptedFilesLocal.value = props.acceptedFiles.map((f: string) => `.${f}`).join(',')

  setDefaultFiles()
})

const setDefaultFiles = () => {
  if (props.modelValue && props.modelValue.length) {
    files.value.push(...props.modelValue)

    defaultFilesUpdated.value = true
  }
}

const onFileChange = e => {
  defaultFilesUpdated.value = true

  fileErorrs.value.size = false

  const uploadedFiles = e.target.files || e.dataTransfer.files

  if (!uploadedFiles.length) return

  createFile(uploadedFiles)
}

const emitFiles = () => {
  setTimeout(() => {
    emit('update:modelValue', files.value)

    fileField.value.value = ''
  }, 100)
}


const dropFile = e => {
  e.preventDefault()

  fileField.value.files = e.dataTransfer.files

  onFileChange(e)
}

const dragover = e => {
  e.preventDefault()
}

const createFile = async uploadedFiles => {
  await validateFile(uploadedFiles)

  const timeout = 300

  setTimeout(() => {
    const isValid = checkErrors()

    if (!isValid) return

    Object.keys(uploadedFiles).forEach(el => {
      const fileEl = uploadedFiles[el]
      const blob = URL.createObjectURL(fileEl)
      const reader = new FileReader()

      reader.onload = e => {
        const { result } = e.target
        const src = props.needCropper ? blob : result
        const fileName = fileEl.name
        const data = `${fileName};${result}`

        file.value = {
          src,
          data,
          type: getMimeType(result, fileEl.type),
          name: fileEl.name,
          size: formatBytes(fileEl.size),
          isUploaded: true,
        }
        if (props.needCropper) {
          // TODO
          // openCropperModal()
        } else {
          files.value.push(file.value)
          emitFiles()
        }
      }

      if (props.needCropper) {
        reader.readAsArrayBuffer(fileEl)
      } else {
        reader.readAsDataURL(fileEl)
      }
    })
  }, timeout)
}

// const cropImageHandler = image => {
//   files.value.push({ src: image })

//   emitFiles()
// }

const openCropperModal = () => {
  // const modal = () => import('@components/Modals/Common/ModalCropper')

  // $modal.show(modal, {
  //   image: file.value,
  //   callback: cropImageHandler,
  //   stencil: 'rectangle-stencil',
  // }, {
  //   class: 'v--modal-md',
  // })
}

const checkErrors = () => {
  return Object.values(fileErorrs.value).every(el => !el)
}

const validateFile = async files => {
  validateFileFormat(files)
  validateFilesLength(files)
  validateFileSize(files)
}

const validateFileFormat = files => {
  fileErorrs.value.type = false

  const isValid = Object.keys(files).every(key => {
    const splittedData = files[key].name.split('.')
    const format = splittedData[splittedData.length - 1]

    return props.acceptedFiles.includes(format)
  })

  fileErorrs.value.type = !isValid
}

const validateFilesLength = arr => {
  fileErorrs.value.limit = false
  // Check if files limit is valid
  if (notDeletedFiles.value.length + arr.length > parseInt(props.maxFiles)) {
    fileErorrs.value.limit = true
  }
}

const validateFileSize = arr => {
  const maxSize = parseInt(props.maxSize) * megabyte
  // Check if files size is valid
  fileErorrs.value.size = Object.keys(arr).some(el => arr[el].size > maxSize)
}

const removeFile = async (index: number) => {
  const info = {
    title: $t('Are you sure you want to delete the file?'),
  }

  await useConfirmWithPromise(info)

  // Get file for delete
  const file = files.value[index]

  if (file && file.id) {
    // Add delete key for images from server
    file.Delete = true
  } else {
    // Remove deleted file if it's not server file
    files.value.splice(index, 1)
  }
  emitFiles()
}

const openUploader = () => {
  fileField.value.click()
}

const resetFiles = () => {
  files.value = []
}

const __returned__ = { props, $t, emit, acceptedFilesLocal, files, fileErorrs, defaultFilesUpdated, file, fileField, notDeletedFiles, setDefaultFiles, onFileChange, emitFiles, dropFile, dragover, createFile, openCropperModal, checkErrors, validateFile, validateFileFormat, validateFilesLength, validateFileSize, removeFile, openUploader, resetFiles, ref, computed, watch, onMounted, get getMimeType() { return getMimeType }, get formatBytes() { return formatBytes }, get megabyte() { return megabyte }, get useConfirmWithPromise() { return useConfirmWithPromise }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})