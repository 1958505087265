export class UserBase {
  determined = false

  info = null
}

export class User extends UserBase {
  constructor(info: Response, determined = true) {
    super()

    this.determined = determined
    this.info = info || this.info
  }
}
