import { App } from 'vue'
import Controller from './ControlDescriptor.vue'
import ControlLabel from './ControlLabel.vue'
import ControlLabelBound from './ControlLabelBound.vue'
import SimpleTextarea from './SimpleTextarea.vue'
import SimpleInput from './SimpleInput.vue'
import Multiselect from './ControlMultiselect.vue'
import ControlButton from './ControlButton.vue'
import Uploader from './ControlUploader.vue'
import Datepicker from './ControlDatepicker.vue'
import Autocomplete from './ControlAutocomplete.vue'
import Switch from './ControlSwitch.vue'
import GoogleAutocomplete from './ControlGoogleAutocomplete.vue'

const register = (app: App<Element>): void => {
  app
    .component('ControlInput', Controller)
    .component('ControlLabel', ControlLabel)
    .component('ControlLabelBound', ControlLabelBound)
    .component('SimpleTextarea', SimpleTextarea)
    .component('SimpleInput', SimpleInput)
    .component('ControlSelect', Multiselect)
    .component('ControlButton', ControlButton)
    .component('ControlUploader', Uploader)
    .component('ControlDatepicker', Datepicker)
    .component('ControlAutocomplete', Autocomplete)
    .component('ControlSwitch', Switch)
    .component('ControlGoogleAutocomplete', GoogleAutocomplete)
}

export default {
  register,
}
