import { App } from 'vue'

import ButtonDetails from './ButtonDetails.vue'
import ButtonToggler from './ButtonToggler.vue'
import FavouriteCatalog from './FavouriteCatalog.vue'

const register = (app: App<Element>): void => {
  app
    .component('favourite-button-details', ButtonDetails)
    .component('favourite-button-toggler', ButtonToggler)
    .component('favourite-catalog', FavouriteCatalog)
}

export default {
  register,
}
