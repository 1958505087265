import { defineComponent as _defineComponent } from 'vue'
import useChoices from '@/choices'


export default /*@__PURE__*/_defineComponent({
  __name: 'UiShare',
  props: {
  url: {
    type: String,
  },
  title: {
    type: String,
  },
  isModal: {
    type: Boolean,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props


const choices = useChoices()

const __returned__ = { props, choices, get useChoices() { return useChoices } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})