import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_view = _resolveComponent("list-view")!

  return (_openBlock(), _createBlock(_component_list_view, {
    "url-path": $setup.urlPath,
    pagination: $props.pagination,
    "list-resource": $setup.restaurantCatalogResource,
    "plural-forms": $setup.pluralForms,
    filters: $props.filters
  }, {
    title: _withCtx(() => [
      _renderSlot(_ctx.$slots, "title")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["url-path", "pagination", "list-resource", "filters"]))
}