import { App } from 'vue'
import Preferences from './Preferences.vue'

const register = (app: App<Element>): void => {
  app
    .component('ProductPreferences', Preferences)
}

export default {
  register,
}
