import { optionsT } from '@/router'
import useRoute from '@router/utils'
import { BASE_URL } from '@router/consts'

const List = () => import('../views/List.vue')

const p = (postfix: string) => `${BASE_URL}${postfix}`

export const LIST_URL = p('restaurant/:restaurantId/category/list/')

export const LIST = 'restaurant:profile:category:list'

export function createRoutes(options: optionsT): any {
  return [
    useRoute(LIST_URL, List, LIST, { props: true }),
  ]
}
