import {
  createResource,
  baseResource,
  postResource,
  patchResource,
  deleteResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'
import { friendlyUrlGenerator } from '@utils/friendly'

const MODEL_PREFIX = 'restaurant'

const FILTERS_LIST_URL_PREFIX = prefixAPI('/list/', MODEL_PREFIX)
const RESTAURANT_RETRIEVE = prefixAPI('/detail/{id}/', MODEL_PREFIX)
const RESTAURANT_UPDATE = prefixAPI('/detail/{id}/', MODEL_PREFIX)
const RESTAURANT_CREATE = prefixAPI('/create/', MODEL_PREFIX)
const RESTAURANT_DELETE = prefixAPI('/delete/', MODEL_PREFIX)

const CATEGORY_CREATE = prefixAPI('/category/create/', MODEL_PREFIX)
const CATEGORY_RETRIEVE = prefixAPI('/category/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const CATEGORY_FILTERS_LIST_URL_PREFIX = prefixAPI('/category/list/', MODEL_PREFIX)

const DISH_RETRIEVE = prefixAPI('/dish/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const DISH_UPDATE = prefixAPI('/dish/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const DISH_CREATE = prefixAPI('/dish/create/', MODEL_PREFIX)
const DISH_DELETE = prefixAPI('/dish/{restaurantId}/delete/{id}/', MODEL_PREFIX)
const DISH_FILTERS_LIST_URL_PREFIX = prefixAPI('/dish/list/', MODEL_PREFIX)

const MENU_RETRIEVE = prefixAPI('/menu/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const MENU_UPDATE = prefixAPI('/menu/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const MENU_CREATE = prefixAPI('/menu/create/', MODEL_PREFIX)
const MENU_DELETE = prefixAPI('/menu/{restaurantId}/delete/{id}/', MODEL_PREFIX)
const MENU_FILTERS_LIST_URL_PREFIX = prefixAPI('/menu/list/', MODEL_PREFIX)

const MANAGER_RETRIEVE = prefixAPI('/manager/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const MANAGER_UPDATE = prefixAPI('/manager/{restaurantId}/detail/{id}/', MODEL_PREFIX)
const MANAGER_CREATE = prefixAPI('/manager/create/', MODEL_PREFIX)
const MANAGER_ADD = prefixAPI('/manager/add/', MODEL_PREFIX)
const MANAGER_FILTERS_LIST_URL_PREFIX = prefixAPI('/manager/list/', MODEL_PREFIX)
const MANAGER_OWN_FILTERS_LIST_URL_PREFIX = prefixAPI('/manager/own/list/', MODEL_PREFIX)

export const restaurantCatalogResource = friendlyUrlGenerator([
  '/restaurants', 'ajax'
], baseResource)

export const restaurantListResource = friendlyUrlGenerator([
  FILTERS_LIST_URL_PREFIX, ''
], baseResource)
export const restaurantCategoryListResource = friendlyUrlGenerator([
  CATEGORY_FILTERS_LIST_URL_PREFIX, ''
], baseResource)
export const restaurantDishListResource = friendlyUrlGenerator([
  DISH_FILTERS_LIST_URL_PREFIX, ''
], baseResource)
export const restaurantMenuListResource = friendlyUrlGenerator([
  MENU_FILTERS_LIST_URL_PREFIX, ''
], baseResource)
export const restaurantManagerListResource = friendlyUrlGenerator([
  MANAGER_FILTERS_LIST_URL_PREFIX, ''
], baseResource)
export const restaurantManagerOwnListResource = friendlyUrlGenerator([
  MANAGER_OWN_FILTERS_LIST_URL_PREFIX, ''
], baseResource)

export const restaurantRetrieveResource = createResource(RESTAURANT_RETRIEVE)
export const restaurantUpdateResource = createResource(RESTAURANT_UPDATE, patchResource)
export const restaurantCreateResource = createResource(RESTAURANT_CREATE, postResource)
export const restaurantDeleteResource = createResource(RESTAURANT_DELETE, postResource)

export const restaurantCategoryCreateResource = createResource(CATEGORY_CREATE, postResource)
export const restaurantCategoryUpdateResource = createResource(CATEGORY_RETRIEVE, patchResource)

export const restaurantDishCreateResource = createResource(DISH_CREATE, postResource)
export const restaurantDishRetrieveResource = createResource(DISH_RETRIEVE)
export const restaurantDishUpdateResource = createResource(DISH_UPDATE, patchResource)
export const restaurantDishDeleteResource = createResource(DISH_DELETE, deleteResource)

export const restaurantMenuRetrieveResource = createResource(MENU_RETRIEVE)
export const restaurantMenuCreateResource = createResource(MENU_CREATE, postResource)
export const restaurantMenuUpdateResource = createResource(MENU_UPDATE, patchResource)
export const restaurantMenuDeleteResource = createResource(MENU_DELETE, deleteResource)

export const restaurantManagerRetrieveResource = createResource(MANAGER_RETRIEVE)
export const restaurantManagerCreateResource = createResource(MANAGER_CREATE, postResource)
export const restaurantManagerUpdateResource = createResource(MANAGER_UPDATE, patchResource)
export const restaurantManagerAddResource = createResource(MANAGER_ADD, postResource)
