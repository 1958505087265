import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue'
import { maps } from '@modules/google-maps-loader'

import type { google } from 'google.maps'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlGoogleAutocomplete',
  props: {
  value: {},
  name: String,
  errors: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const internal = ref('')
const autocompleteInput = ref<HTMLElement | null>(null)
const autocomplete = ref(null)

watch(() => props.value, () => {
  if (props.value?.address) {
    internal.value = props.value.address
  }
})

onMounted(async () => {
  await maps().then(setupAutocomplete)
})

function setupAutocomplete() {
  if (autocompleteInput.value) {
    autocomplete.value = new google.maps.places.Autocomplete(
      autocompleteInput.value
    )

    autocomplete.value.addListener('place_changed', async () => {
      const place = autocomplete.value.getPlace()

      internal.value = autocompleteInput.value.value

      const location = await place.geometry.location.toJSON()

      emit('update:modelValue', {
        address: autocompleteInput.value.value,
        lat: location.lat,
        lng: location.lng,
      })
    })
  }
}

const __returned__ = { props, emit, internal, autocompleteInput, autocomplete, setupAutocomplete, ref, watch, onMounted, get maps() { return maps } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})