import { App } from 'vue'

import Ui from './Ui'
import Profile from './Profile'
import Controls from './Controls'
import Modals from './Modals'
import Favourite from './Favourite'
import Forms from './Forms'
import Restaurant from './Restaurant'
import List from './List'
import Authentication from './Authentication'
import Product from './Product'

const install = (app: App<Element>): void => {
  Ui.register(app)
  Profile.register(app)
  Controls.register(app)
  Modals.register(app)
  Favourite.register(app)
  Forms.register(app)
  Restaurant.register(app)
  List.register(app)
  Authentication.register(app)
  Product.register(app)
}

export default {
  install,
}
