import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ripple-container",
    onClick: $setup.createRipple
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ripples, (ripple) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "ripple",
        key: ripple.id,
        style: _normalizeStyle(ripple.style)
      }, null, 4))
    }), 128))
  ]))
}