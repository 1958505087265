import { defineComponent as _defineComponent } from 'vue'
import VueMultiselect from 'vue-multiselect'
import { ref, computed, watch, useSlots } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ControlMultiselect',
  props: {
  options: {
    type: Array,
    default: () => [],
  },
  trackBy: {
    type: String,
  },
  optionLabel: {
    type: String,
  },
  modelValue: [Array, Object, String, Number],
  name: String,
  errors: {
    type: Object,
    default: () => ({}),
  },
  inputLabel: String,
  ajaxResults: Boolean,
  rules: {
    type: [String, Object],
    default: '',
  },
  labelComponent: {
    type: String,
    default: 'ControlLabel',
  },
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit
const slots = useSlots()

const isRequired = ref(false)
const model = ref(props.modelValue)

const eSlotsResolver = (acc, key) => {
  acc[key] = key

  return acc
}

const getESlots = keys => keys.reduce(eSlotsResolver, {})

const eSlots = computed(() => {
  return getESlots([...Object.keys(slots)])
})

if (typeof props.rules === 'object' && props.rules !== null) {
  isRequired.value = props.rules?.required || false
} else {
  isRequired.value = props.rules.includes('required')
}

const defaultOptions = computed(() => {
  return {
    openDirection: 'bottom',
    tagPlaceholder: '',
    placeholder: '',
    showLabels: false,
    closeOnSelect: true,
    searchable: false,
    taggable: false,
  }
})

watch(() => props.modelValue, () => {
  if (typeof props.modelValue === 'string') return

  model.value = props.modelValue
})

const updateValue = () => {
  emit('update:modelValue', model.value)
}

const __returned__ = { props, emit, slots, isRequired, model, eSlotsResolver, getESlots, eSlots, defaultOptions, updateValue, get VueMultiselect() { return VueMultiselect }, ref, computed, watch, useSlots }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})