import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "file-uploader" }
const _hoisted_2 = ["id", "name", "accept", "multiple"]
const _hoisted_3 = { class: "file-uploader__icon" }
const _hoisted_4 = { class: "file-uploader__content" }
const _hoisted_5 = { class: "ds-caption ds-caption--size_sm ds-caption--size_md-xl ds-caption--weight_bold" }
const _hoisted_6 = { class: "ds-caption ds-caption--size_2xxs ds-caption--size_xxs-xl ds-caption--color_relief-3" }
const _hoisted_7 = {
  key: 0,
  class: "file-uploader__list"
}
const _hoisted_8 = { class: "file-uploader-image" }
const _hoisted_9 = { class: "file-uploader-image__wrapper" }
const _hoisted_10 = { class: "file-uploader-image__content" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "file-uploader__errors"
}
const _hoisted_14 = {
  key: 0,
  class: "error-label"
}
const _hoisted_15 = {
  key: 1,
  class: "error-label"
}
const _hoisted_16 = {
  key: 2,
  class: "error-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_circle_plus = _resolveComponent("icon-circle-plus")!
  const _component_icon_trash = _resolveComponent("icon-trash")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["file-uploader__dropzone", {'is-disabled': $setup.notDeletedFiles.length >= $setup.props.maxFiles}]),
      onDrop: $setup.dropFile,
      onDragover: $setup.dragover
    }, [
      _createElementVNode("input", {
        ref: "fileField",
        style: {"display":"none"},
        type: "file",
        id: $props.inputId,
        name: $props.name || $props.inputId,
        accept: $setup.acceptedFilesLocal,
        multiple: $props.multiple,
        onChange: $setup.onFileChange
      }, null, 40, _hoisted_2),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_icon_circle_plus)
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString($props.label), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString($setup.$t("Drag files here or click the button.")), 1)
      ])
    ], 34),
    ($setup.notDeletedFiles.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.notDeletedFiles, (file, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "file-uploader__file",
              key: index
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("img", {
                      class: "file-uploader-image__image",
                      src: file.src
                    }, null, 8, _hoisted_11)
                  ]),
                  (!$props.disabled)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "file-uploader-image__close",
                        onClick: _withModifiers(($event: any) => ($setup.removeFile(index)), ["prevent"])
                      }, [
                        _createVNode(_component_icon_trash)
                      ], 8, _hoisted_12))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    ($setup.fileErorrs.limit || $setup.fileErorrs.size || $setup.fileErorrs.type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          ($setup.fileErorrs.limit)
            ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString($setup.$t("The maximum number of files to upload has been exceeded")), 1))
            : _createCommentVNode("", true),
          ($setup.fileErorrs.size)
            ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString($setup.$t("Maximum file size for upload exceeded")), 1))
            : _createCommentVNode("", true),
          ($setup.fileErorrs.type)
            ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString($setup.$t("Unsupported file format")), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}