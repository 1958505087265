import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createBlock(_component_GMapMap, {
    class: "map-wrapper map-wrapper--variant_1",
    ref: "mapRef",
    center: $setup.MAP_CONFIG.center,
    zoom: $setup.MAP_CONFIG.zoom,
    options: $setup.MAP_CONFIG.options,
    onBounds_changed: $setup.setBounds
  }, {
    default: _withCtx(() => [
      _createVNode(_component_GMapMarker, {
        position: { lat: Number($props.lat), lng: Number($props.lng) }
      }, null, 8, ["position"])
    ]),
    _: 1
  }, 8, ["center", "zoom", "options"]))
}