import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_view_profile = _resolveComponent("list-view-profile")!

  return (_openBlock(), _createBlock(_component_list_view_profile, {
    ref: "list",
    "url-path": $props.urlPath,
    pagination: $props.pagination,
    "list-resource": $setup.RECOURCE_MAP[$props.catalogType],
    "cards-wrapper-class": $props.cardsWrapperClass,
    "prevent-push-state": true
  }, {
    additional: _withCtx(() => [
      _renderSlot(_ctx.$slots, "additional")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["url-path", "pagination", "list-resource", "cards-wrapper-class"]))
}