import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@components/Forms/FormMixin'
import { feedbackCreateResource } from '@services/feedback.service'
import { useShowModal } from '@composables/useModalOpener'
import useGlobal from '@composables/useGlobal'


export default /*@__PURE__*/_defineComponent({
  __name: 'Feedback',
  props: {
  close: {
    type: Function,
  },
  fullwidth: {
    type: Boolean,
    default: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const { $t } = useGlobal()
const isLoad = ref(false)

const formdata = reactive({
  name: '',
  email: '',
  phone: '',
  message: '',
  source: '',
})

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  preparedFormdata.source = window.location.href

  return preparedFormdata
}

const send = async (sendData: object, control: any) => {
  if (isLoad.value) return

  isLoad.value = true

  const preparedFormdata = prepareFormdata()

  feedbackCreateResource.execute({}, preparedFormdata).then(async () => {
    const message = {
      title: $t('Thank you for the request'),
      text: $t('Your application has been accepted'),
    }

    useShowModal(message)

    if (props.close) {
      props.close()
    } else {
      control.setValues({
        email: '',
        name: '',
        phone: '',
        message: '',
      })
    }
  }).catch(async (e: object) => {
    const parsed = await useErrorsGetter(e)

    useParsedSetter(parsed, control.setFieldError)
  }).finally(() => {
    isLoad.value = false
  })
}

const __returned__ = { props, $t, isLoad, formdata, prepareFormdata, send, ref, reactive, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get feedbackCreateResource() { return feedbackCreateResource }, get useShowModal() { return useShowModal }, get useGlobal() { return useGlobal } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})