import { optionsT } from '@router/index'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createErrorsRoutes } from './modules/errors/router'
import { createRoutes as createRestaurantRoutes } from './modules/restaurant/router'
import { createRoutes as createFavouriteRoutes } from './modules/favourite/router'
import { createRoutes as createCategoryRoutes } from './modules/category/router/index'
import { createRoutes as createDishRoutes } from './modules/dish/router/index'
import { createRoutes as createMenuRoutes } from './modules/menu/router/index'
import { createRoutes as createManagerRoutes } from './modules/manager/router/index'

export function createRoutes(options: optionsT): any {
  return [
    ...createSettingsRoutes(options),
    ...createErrorsRoutes(options),
    ...createRestaurantRoutes(options),
    ...createFavouriteRoutes(options),
    ...createCategoryRoutes(options),
    ...createDishRoutes(options),
    ...createManagerRoutes(options),
    ...createMenuRoutes(options),
  ]
}
