import {
  createResource,
  patchResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'account'

const USER_RETRIEVE = prefixAPI('/detail/', MODEL_PREFIX)
const USER_UPDATE = prefixAPI('/detail/', MODEL_PREFIX)
const USER_PASSWORD_CHANGE = prefixAPI('/password-change/', MODEL_PREFIX)
const USER_EMAIL_CHANGE = prefixAPI('/email-change/', MODEL_PREFIX)

export const userRetrieveResource = createResource(USER_RETRIEVE)
export const userUpdateResource = createResource(USER_UPDATE, patchResource)
export const userPasswordChangeResource = createResource(USER_PASSWORD_CHANGE, postResource)
export const userEmailChangeResource = createResource(USER_EMAIL_CHANGE, postResource)
